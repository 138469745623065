.boat {
    display: flex;
    flex-direction: column;
    align-items: center;
  
    @media (min-width: 769px) {
      flex-direction: row;
    }
  }
  
  .profile-pic {
    margin-bottom: 20px;
  
    @media (min-width: 769px) {
      margin-right: 20px;
    }
  }
  
  .my-image {
    //max-width: 100%;
    //height: auto;
    object-fit: unset;
    width: fit-content;
  }

.home-page {
    .text-zone {
        position: absolute;
        left: 27%;
        top: 50%;
        transform: translateY(-50%);
        width: 90%;
        max-height: 90%;
        text-align: justify;
    }
    
    .home-page {
        .logo-container {
                   position: relative;
                   width: 300px;
                   height: auto;
                   align-items: center;
                   top: 50px;
                   float: none;
                   right: -75px;
                     margin: auto;
                    width: 60%;
                    border: 3px solid #73AD21;
                    padding: 10px;
                   //margin-bottom:
                   box-sizing: border-box;
                   //left: 0;
                   
                   svg {
                       position: absolute;
                       top: auto;
                       right: auto;
                       bottom: auto;
                       left: 0;
                       margin: auto;
                   }
               }
    }


    h1 {
        color: #000 ;
        font-size: 53px;
        margin: 0;
        font-family: 'Coolvetica';
        font-weight: 400;
        &::before {
            content: '';
            font-family: 'La Belle Aurore';
            color: #000;
            font-size: 18px;
            position: absolute;
            margin-top: -40px;
            left: 15px;
            opacity: 0.6;
        }

        &::after {
            content: '';
            font-family: 'La Belle Aurore';
            color: #000;
            font-size: 18px;
            position: absolute;
            margin-top: 18px;
            margin-left: 20px;
            animation: fadeIn 1s 1.7s backwards;
            opacity: 0.6;
        }

        img {
            width: 32px;
            margin-left: 20px;
            opacity: 0;
            height: auto;
            animation: rotateIn 1s linear both;
            animation-delay: 1.4s;
        }
    }

    h2 {
        color: #000 ;
        margin-top: 20px;
        font-weight: 800;
        font-size: 11px;
        font-family: sans-serif;
        letter-spacing: 3px;
        animation: fadeIn 1s 1.8s backwards;
    }

    .flat-button {
        color: #4169e1 ;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 4px;
        font-family: sans-serif;
        text-decoration: none;
        padding: 10px 18px;
        border: 1px solid #4169e1 ;
        margin-top: 25px;
        float: left;
        animation: fadeIn 1s 1.8s backwards;
        white-space: nowrap;

        &:hover {
            background: #4169e1 ;
            color: #fff;
        }
    }
}

@media screen and (max-width: 1200px) {
    .tags,
    .home-page h1::before,
    .home-page h1::after {
        display: none;
    }

  

    .home-page {
        .text-zone {
            position: initial;
            width: 100%;
            transform: none;
            padding: 10px;
            box-sizing: border-box;
        }
        h1 {
            font-size: 45px;
            text-align: left;
            justify-content: center;
            padding-left: 15%;
        }
        h2 {
            color: #000 ;
            margin-top: 20px;
            font-weight: 800;
            font-size: 11px;
            font-family: sans-serif;
            letter-spacing: 3px;
            animation: fadeIn 1s 1.8s backwards;
            padding-left: 15%;
        }

        .flat-button {
            float: none;
            display: block;
            margin: 20px auto 0 auto;
            width: 125px;
        }

        .logo-container {
            position: relative;
            width: 300px;
            height: auto;
            align-items: center;
            top: 50px;
            float: none;
            right: -75px;
              margin: auto;
  width: 60%;
  border: 3px solid #73AD21;
  padding: 10px;
            //margin-bottom:
            box-sizing: border-box;
            //left: 0;
            
            svg {
                position: absolute;
                top: auto;
                right: auto;
                bottom: auto;
                left: 0;
                margin: auto;
            }
        }
    }
}