.nav-bar {
    background: #181818;
    width: 60px;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 3;
    min-height: 500px;
    
    .burger-show {
        display: none;

        @media screen and (max-width: 1200px){
            display: initial;
        }
    }

    .logo {
        display: block;
        padding: 8px 0;
        margin-top: -16px;
       

    img {
        display: block;
        margin: 8px auto;
        height: auto;
        width: 100%;
        border-radius: 50%;

        &.sub-logo {
            width: 50px;
        } 
    }
    
}


    nav {
        display: block;
        text-align: center;
        position: absolute;
        height: 210px;
        top: 50%;
        margin-top: -120px;
        width: 100%;

        a svg {
            opacity: 1 !important;
            color: white;
        }

        a {
            font-size: 22px;
            color: #4d4d4e;
            display: block;
            line-height: 51px;
            height: 51px;
            position: relative;
            text-decoration: none;

            i {
                transition: all 0.3s ease-out;
            }

            

            &:after {
                content: '';
                font-size: 9px;
                letter-spacing: 2px;
                position: absolute;
                bottom: 0;
                display: block;
                width: 100%;
                text-align: center;
                opacity: 0;
                -webkit-transition: all 0.3s ease-out;
                transition: all 0.3s ease-out;
            }
        }
        a.active{
            svg {
                color: #4169e1 ;
            }
        }

        a.nav-button-icon {
            
            
            &:hover svg {
                color: #4169e1 ;
            }
        }        
    }

    ul {
        position: absolute;
        bottom: 20px;
        width: 100%;
        display: block;
        padding: 0;
        list-style: none;
        text-align: center;
        margin: 0;
        

        li {
            a {
                padding: 7px 0;
                display: block;
                font-size: 15px;
                line-height: 16px;
                color: #fff;

                svg {
                    color: white;
                }

                &:hover svg {
                    color: #4169e1;
                }
            }
        }
    }
}

.burger-icon,
.close-icon {
    display: none;
}

@media screen and (max-width: 1200px) {
    .nav-bar {
        background: transparent;
        position: initial;
        height: auto;
        min-height: auto;
        margin: 0;
            padding: 0;
            
        

        ul, nav {
            display: none;
            margin: 0;
            padding: 0;
           
        }

        nav {
            // display: block;
            width: 90%;
            height: 50%;
            background: #181818;
            top: 0;
            right: 0;
            border-radius: 33px;
            z-index: 2;
            margin: 0;
           padding: 0;

            a {
                position: relative;
                margin-left: 40%;
               
                justify-content: left;
                align-items: center;
                color: white;
                margin: 0;
                padding: 0;
            }
    
            a:after {
                opacity: 1;
                position: initial;
                width: auto;
                margin-left: 10px;
            }

            a svg {
                opacity: 1 !important;
                color: white;
            }

            &.mobile-show {
                display: block;
            }
            .mobile-show{
                &:hover {
                    color: #000;
                }
            }
        }

        .burger-icon {
            display: block;
            position: absolute;
            top: 15px;
            right: 15px;
            color: black;
        }
        .close-icon {
            display: block;
            position: absolute;
            top: 15px;
            right: 15px;
            color: white;
        }

        
    }
  }

