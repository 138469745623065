.contact-page {
    background-color: #bcbec4;

}

.contact-form {
    width: 100%;
    margin-top: 20px;

    ul {
        padding: 0;
        margin: 0;

        li {
            padding: 0;
            margin: 0;
            list-style: none;
            margin-bottom: 10px;
            overflow: hidden;
            display: block;
            position: relative;
            opacity: 0;
            animation: fadeInUp 2s 2s;
            animation-fill-mode: forwards;
            clear: both;
        }

        li.half {
            width: 49%;
            margin-left: 2%;
            float: left;
            clear: none;

            &:first-child {
                margin-left: 0;
            }
            ::placeholder {
                color: white;
                opacity: 0.8;
            }
        }

        li.full {
            ::placeholder {
                color: white;
                opacity: 0.8;
            }
        }
    }

    input[type='text'],
    input[type='email'] {
        width: 100%;
        border: 0;
        background: #000000;
        opacity: 0.75;
        height: 50px;
        font-size: 16px;
        color: rgb(255, 255, 255);
        padding: 0 20px;
        box-sizing: border-box;
    }

    textarea {
        width: 100%;
        border: 0;
        background:  rgb(0, 0, 0);
        opacity: 0.75;
        height: 50px;
        font-size: 16px;
        color: white;
        padding: 20px;
        min-height: 150px;
        box-sizing: border-box;
    }

    .flat-button {
        color: #ffffff ;
        background: #000000;
        font: 11px;
        letter-spacing: 3px;
        text-decoration: none;
        padding: 1px solid #4169e1 ;
        border: 1px solid #4169e1 ;
        float: right;
        border-radius: 4px;

        &:hover {
            color: #fff;
            background: #4169e1;
        }
    }
 }

 .info-map {
    position: absolute;
    background-color: black;
    top: 50px;
    right: 7.5%;
    z-index: 99999;
    width: 267px;
    padding: 20px;
    color: rgb(255, 255, 255);
    font-family: 'Helvetica';
    font-style: 17px;
    font-weight: 300;
    opacity: 0;
    animation: fadeIn 1s 1.5s;
    animation-fill-mode: forwards;

    span {
        font-size: 16px;
        display: block;
        padding-top: 20px;
        color: #4169e1 ;
    }
 }

 .map-wrap {
    background: rgba(8, 253, 216, 0.1);
    float: right;
    width: 53%;
    height: 100%;
 }

 .leaflet-container {
    width: 100%;
    height: 100%;
    position: relative;
    opacity: 0;
    animation: backInRight 1s 1.2s;
    animation-fill-mode: forwards;
 }

@media screen and (max-width: 1200px) {
    .map-wrap{
        float: none;
        width: 100%;
        height: 400px;
        margin: 0;
    }

    .info-map {
        display: none;
    }
}